import { checkRemontPermission } from "./api";

export const checkRemontPermissionData = async remont_id => {
  if (!remont_id) return true;
  if (isNaN(remont_id)) return false;
  try {
    const res = await checkRemontPermission(remont_id);
    return !!res?.status;
  } catch (e) {
    return false;
  }
};
