import React, { useState } from "react";
import { MenuItem, Popover, Tab, Tabs } from "@material-ui/core";
import { additionalTabs } from "./index";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const MainTabs = ({ value, handleChange, setMenuItem, menuItem }) => {
  const classes = useStyles();

  const initialTab = additionalTabs?.find(item => item.key === menuItem);
  const [state, setState] = useState({
    label: initialTab?.label || "Другое",
    anchor: null
  });

  const open = Boolean(state.anchor);
  const handleClick = e => {
    setState({ ...state, anchor: e.currentTarget });
  };

  const handleClose = () => {
    setState({ ...state, anchor: null });
  };

  const handleMenuItemClick = (item, label) => {
    setState({ label, anchor: null });
    setMenuItem(item);
  };

  return (
    <>
      <div>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          indicatorColor="primary"
          textColor="primary"
          classes={{ root: classes.tabs_root, scroller: classes.tabs_scroller }}
        >
          {mainTabs?.map((item, i) => {
            return (
              <Tab
                key={i}
                classes={{
                  root: classes.tabs_item
                }}
                label={item?.label || ""}
                {...a11yProps(i)}
              />
            );
          })}
          <Tab
            classes={{
              root: classes.tabs_item
            }}
            label={state.label || "Другое"}
            icon={
              <ArrowDropDownIcon
                style={{
                  transform: "translateY(3px)"
                }}
              />
            }
            style={{
              textTransform: "none"
            }}
            onClick={handleClick}
          />
        </Tabs>
      </div>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={state.anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {additionalTabs?.map(item => {
          return (
            <MenuItem
              key={item?.key}
              onClick={() => handleMenuItemClick(item?.key, item?.label)}
            >
              {item?.label || ""}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default MainTabs;

const mainTabs = [
  { label: "О ремонте" },
  { label: "Заявки" },
  { label: "Замеры" },
  { label: "Работы" },
  { label: "Мастера" },
  { label: "Этапы" },
  { label: "Учет" }
];

export const useStyles = makeStyles(() => ({
  tabs_root: {
    minHeight: 64
  },
  tabs_item: {
    fontSize: "14px",
    color: "#9194A9",
    textTransform: "capitalize",
    minWidth: 100,
    "&.MuiTab-labelIcon": {
      minHeight: 0
    },
    "&:last-child": {
      "& .MuiTab-wrapper": {
        flexDirection: "row-reverse",
        transform: "translateY(-3px)"
      }
    }
  },
  tabs_scroller: {
    display: "flex"
  }
}));
