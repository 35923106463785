import React from "react";
import Statuses from "../Statuses";
import Projects from "../Projects";
import Gpr from "../RemontInfo/Gpr/Gpr";
import TextConstructor from "../RemontInfo/TextConstructor/TextConstructor";
import SmetaMaterials from "../RemontInfo/SmetaMaterials/SmetaMaterials";
import EstimateTable from "../estimate/EstimateTable";
import Chat from "../Chat";
import AdditionalWorks from "../AdditionalWorks";
import DiscrepanciesReport from "../estimate/DiscrepanciesReport";
import { CheckList } from "../CheckList";
import Documents from "../Documents";
import { main_tabs } from "../index";
import RemontsProblem from "../RemontsProblem";
import { KeyReceive } from "../KeyReceive";

const AdditionalTab = ({ menuItem }) => {
  const getContent = () => {
    switch (menuItem) {
      case main_tabs.statuses:
        return <Statuses />;
      case main_tabs.projects:
        return <Projects />;
      case main_tabs.remonts_problem:
        return <RemontsProblem />;
      case main_tabs.gpr:
        return <Gpr />;
      case main_tabs.text_construct:
        return <TextConstructor />;
      case main_tabs.materials_smeta:
        return <SmetaMaterials />;
      case main_tabs.history_delivery_materials:
        return <EstimateTable />;
      case main_tabs.key_receive:
        return <KeyReceive />;
      case main_tabs.diff_smeta:
        return <DiscrepanciesReport />;
      case main_tabs.chat:
        return <Chat />;
      case main_tabs.add_works:
        return <AdditionalWorks />;
      case main_tabs.check_lists:
        return <CheckList />;
      case main_tabs.documents:
        return <Documents />;
      default:
        return "";
    }
  };

  return <div>{getContent()}</div>;
};

export default AdditionalTab;
