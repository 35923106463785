import axios from "axios";
import { API_URL } from "../../constants";
import toast from "../../components/common/helpers/snackbar";
import { handleError } from "./helpers";
import cookiesService from "../cookiesService";
import { convertArrayBufferToStr } from "../../utils";

export let source;

const cookiesServiceInstance = cookiesService.getService();
const instance = (authorization = true, url = null, options = {}) => {
  const reqOptions = {
    isThirdParty: false,
    throwError: false,
    showToast: true,
    ...options
  };
  const { showToast, throwError } = reqOptions;

  source = axios.CancelToken.source();

  const baseURL = url || API_URL
  const axiosInstance = axios.create({
    baseURL,
    cancelToken: options.cancelToken || source.token
  });
  if (authorization) {
    axiosInstance.interceptors.request.use(
      config => {
        if (options?.isBasic)
          config.headers.Authorization = "Basic aHNfc21hcnRyZW1vbnQ6ZWlkI2RsUmc1I0BLZDk4OQ==";
        else {
          const token = cookiesServiceInstance.getAccessToken();
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      console.log(error)
      if (showToast && !axios.isCancel(error)) {
        let message = error?.response?.data?.error;
        const defaultMessage = error.response?.status
          ? `${error.response?.status}, ${error?.response?.statusText}`
          : "Ошибка";

        if (error?.response?.config?.responseType === "arraybuffer") {
          const response = convertArrayBufferToStr(error?.response?.data);
          message = response?.error;
        }
        toast.error(message || error?.message || defaultMessage);
      }
      handleError(error, source.cancel);
      return throwError
        ? Promise.reject(error)
        : Promise.reject("Что-то пошло не так!");
    }
  );
  return axiosInstance;
};

export default instance;
