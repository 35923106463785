import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//Всплывашки сбоку
import { PAGES, PAGES_ROUTE_KEY } from "../../constants";
//Actions
import { setMasterList, showRightDrawerAction } from "../../services/redux/actions/remonts-actions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMastersList } from "./services/api";
import { ComplexesList } from "./complexesList/complexesList";
import Measure from "./measure/Measure";
import RemontsHeader from "./RemontsHeader";
import RemontInfo from "./RemontInfo";
import AdditionalTab from "./AdditionTab";
import Stages from "./Stages";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";
import { fetchRemontInfo } from "./RemontInfo/services/api";
import { RemontWrapper } from "../../components/common/RemontWrapper";
import { PlaceholderImg } from "../../components/common/blocks/PlaceholderImg";
import { RemontWorksets } from "./Works";
import { TabPanel as CustomTabPanel } from "../../components/common/CustomTabs";
import { AccountingLayout } from "../../components/features/accounting/layout";
import BrigadaInfo from "./brigada";
import { CheckRemontPermission } from "./CheckRemontPermission";
import clsx from "clsx";
import { setHeaderBack } from "../../services/redux/actions/app-actions";
import { Requests } from "../../components/features/requests";

const DEFAULT_OPEN_TAB = 0;

export const RemontPage = ({ isSingleRemont }) => {
  return (
    <CheckRemontPermission>
      <RemontPageWrapper isSingleRemont={isSingleRemont} />
    </CheckRemontPermission>
  );
};
const RemontPageWrapper = ({ isSingleRemont }) => {
  document.title = "Ремонты";
  const dispatch = useDispatch();
  const router = useNavigate();
  const classes = useStyles();
  const { remont_id: remont_id_str, remont_status } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const remont_id = Number(remont_id_str);

  const { resident } = useSelector(state => state.remontsState);
  const [value, setValue] = useState(
    Number(query?.get("active")) || DEFAULT_OPEN_TAB
  );

  const changeActiveTab = useCallback(value => {
    setValue(value);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(showRightDrawerAction(false, {}));
  };

  useEffect(() => {
    getMastersList().then((answer = {}) => {
      dispatch(setMasterList(answer.data));
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(showRightDrawerAction(false, {}));
  }, [remont_id, dispatch]);

  const showMobileSidebar = useMemo(() => {
    if (window.innerWidth > 768) return true;
    return !remont_id;
  }, [remont_id]);

  const showContent = useMemo(() => {
    if (window.innerWidth > 768) return true;
    return !!remont_id;
  }, [remont_id]);

  useEffect(() => {
    const goBack = () => {
      router(`${PAGES.remontsPage}/${remont_status}`);
    };
    !!remont_id && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_status, router, remont_id, value]);

  return (
    <RemontWrapper>
      <Grid container className={classes.wrapper}>
        {!isSingleRemont && (
          <Grid
            item
            xs={3}
            className={clsx(classes.sidebar, { [classes.hidden]: !showMobileSidebar })}
          >
            <ComplexesList
              min
              remontRoute={`${PAGES_ROUTE_KEY.remontsPage}/${remont_status}`}
            />
          </Grid>
        )}

        {showContent && <Grid
          item
          xs={(isSingleRemont || window.innerWidth <= 768) ? 12 : 9}
          className={classes.content}
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{
              height: "100vh",
              backgroundColor: !resident?.remont_id ? "#fff" : null
            }}
          >
            <Main
              handleChange={handleChange}
              changeActiveTab={changeActiveTab}
              value={value}
              remont_id={remont_id}
            />
          </Box>
        </Grid>}
      </Grid>
    </RemontWrapper>
  );
};

const Main = ({ handleChange, value, remont_id, changeActiveTab }) => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [menuItem, setMenuItem] = useState(query.get("tab") || "");
  const [remontData, setRemontData] = useState({});
  const [isFetchingRemontData, setIsFetchingRemontData] = useState(false);

  useEffect(() => {
    const isActiveTabExist = menuItem && !!main_tabs[menuItem];
    if (menuItem && !isActiveTabExist) {
      setMenuItem("");
      changeActiveTab && changeActiveTab(DEFAULT_OPEN_TAB);
    }
    history(`${location.pathname}?active=${value}&tab=${menuItem}`);
  }, [menuItem, value, history, changeActiveTab, location.pathname]);

  useEffect(() => {
    setIsFetchingRemontData(true);
    remont_id &&
    fetchRemontInfo(remont_id)
      .then(res => {
        setIsFetchingRemontData(false);
        setRemontData(res?.data);
      })
      .catch(() => {
        setIsFetchingRemontData(false);
      });
  }, [remont_id]);

  if (!remont_id) {
    return (
      <Box className={classes.placeholder_wrapper}>
        <PlaceholderImg text="Выберите ремонт" />
      </Box>
    );
  }

  return (
    <>
      <RemontsHeader
        value={value}
        menuItem={menuItem}
        handleChange={handleChange}
        setMenuItem={setMenuItem}
      />
      <WrapperScrollbar className={clsx(classes.nav)}>
        <CustomTabPanel
          value={value}
          index={0}
          padding={"0"}
          background={"#fff"}
        >
          <RemontInfo data={remontData} isFetching={isFetchingRemontData} setRemontData={setRemontData} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} padding={21}>
          <Requests showFilters={false} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} padding={21}>
          <div style={{ minHeight: "80vh" }}>
            <Measure />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <RemontWorksets />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <BrigadaInfo remontData={remontData} setRemontData={setRemontData} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Stages />
        </CustomTabPanel>
        {remontData?.is_show_finance && (
          <CustomTabPanel value={value} index={6}>
            <AccountingLayout type={"all"} showFilters={false} />
          </CustomTabPanel>
        )}
        <CustomTabPanel value={value} index={7}>
          <AdditionalTab menuItem={menuItem} />
        </CustomTabPanel>
      </WrapperScrollbar>
    </>
  );
};

export const main_tabs = {
  info: "info",
  works: "works",
  worksets: "worksets",
  request_materials: "request_materials",
  measure: "measure",
  remonts_problem: "remonts_problem",
  stages: "stages",
  add_tab: "add_tab",
  statuses: "statuses",
  projects: "projects",
  brigada: "brigada",
  accounting: "accounting",
  gpr: "gpr",
  text_construct: "text_construct",
  materials_smeta: "materials_smeta",
  history_delivery_materials: "history_delivery_materials",
  key_receive: "key_receive",
  diff_smeta: "diff_smeta",
  chat: "chat",
  add_works: "add_works",
  check_lists: "check_lists",
  documents: "documents"
};

export const additionalTabs = [
  {
    key: main_tabs.statuses,
    label: "Статусы"
  },
  {
    key: main_tabs.projects,
    label: "Проекты"
  },
  {
    key: main_tabs.remonts_problem,
    label: "Обращения"
  },
  {
    key: main_tabs.gpr,
    label: "ГПР"
  },
  {
    key: main_tabs.text_construct,
    label: "Текстовый конструктор"
  },
  {
    key: main_tabs.materials_smeta,
    label: "Материалы сметы"
  },
  {
    key: main_tabs.history_delivery_materials,
    label: "История отгрузки материалов"
  },
  {
    key: main_tabs.key_receive,
    label: "Передача ключей"
  },
  {
    key: main_tabs.diff_smeta,
    label: "Расхождения по смете"
  },
  {
    key: main_tabs.chat,
    label: "Чат"
  },
  {
    key: main_tabs.add_works,
    label: "Доп. Работы"
  },
  {
    key: main_tabs.check_lists,
    label: "Чек-листы"
  },
  {
    key: main_tabs.documents,
    label: "Документы"
  }
];
export const useStyles = makeStyles((theme) => ({
  tabs_root: {
    minHeight: 64
  },
  tabs_scroller: {
    display: "flex"
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#373737"
  },
  subtitle: {
    fontSize: 14
  },
  titleInline: {
    display: "flex",
    gap: "5px",
    padding: "0 15px",
    [theme.breakpoints.down(768)]: {
      padding: 0
    }
  },
  select: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0C4B86",
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  subHeader: {
    background: "#ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    padding: "16px"
  },
  listItem: {
    "& > *": {
      marginRight: "10px",
      marginLeft: "10px"
    }
  },
  nav: {
    flexGrow: 1,
    paddingRight: "6px",
    overflowY: "auto",
    backgroundColor: "#fff",
    minHeight: "625px"
  },
  box: {
    background: "#F8F8F8",
    border: "1px solid #ECEEFD",
    padding: "10px 20px",
    borderRadius: "50%"
  },
  placeholder_wrapper: {
    textAlign: "center",
    backgroundColor: "#fff",
    "& img": {
      maxWidth: "100%"
    },
    [theme.breakpoints.down(768)]: {
      display: "none"
    }
  },
  box_item: {
    width: 65
  },
  additionalBtn: { borderRadius: 4, margin: "0", padding: "5px", width: "fit-content", fontSize: "13px", lineHeight: "18px" },
  info__wrapper: {
    padding: "16px",
    [theme.breakpoints.down(576)]: {
      padding: "0",
      flexWrap: "wrap",
      gap: "15px"
    }
  },
  info_items: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 0.8fr)",
    gridColumnGap: "0px",
    gridRowGap: "12px",
    [theme.breakpoints.down(576)]: {
      display: "block"
    }
  },
  info__column_center: {
    width: "40%",
    [theme.breakpoints.down(768)]: {
      width: "auto"
    }
  },
  info__column_right: {
    width: "19%",
    [theme.breakpoints.down(768)]: {
      width: "auto"
    }
  },
  btn: {
    width: "100%",
    height: "auto",
    minHeight: "28px",
    borderRadius: 4,
    boxShadow: "none",
    fontSize: 14,
    lineHeight: "17px",
    textAlign: "center",
    fontWeight: 400,
    "&:hover": {
      boxShadow: "none"
    },
    textTransform: "none"
  },
  confirm: {
    backgroundColor: "#0C4B86",
    color: "#fff",
    marginTop: 15,
    "&:hover": {
      backgroundColor: "#437ae7"
    }
  },
  cancel: {
    backgroundColor: "#FF6262",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff4646"
    }
  },
  download_docs: {
    "& div": {
      top: 25
    },
    "& p": {
      display: "inline-flex"
    }
  },
  green: {
    color: "green",
    fontWeight: 600
  },
  blue: {
    color: "#0C4B86",
    cursor: "pointer"
  },
  red: {
    color: "#FF6262",
    fontWeight: 600
  },
  hidden: {
    display: "none"
  },
  wrapper: {
    flexWrap: "nowrap",
    [theme.breakpoints.down(768)]: {
      display: "block"
    }
  },
  content: {
    minWidth: 450,
    [theme.breakpoints.down(768)]: {
      minWidth: "unset"
    }
  },
  contentCollapsed: {
    maxWidth: "calc(100% - 60px)",
    flexBasis: "calc(100% - 60px)"
  },
  sidebar: {
    minWidth: 220,
    maxWidth: "unset",
    transition: "all .3s"
  }
}));
