import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { PageTitle } from "../../components/common/pageTitle";
import { RemontWrapper } from "../../components/common/RemontWrapper";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";
import { Requests } from "../../components/features/requests";

const RemontRequests = () => {
  const [requestsCount, setRequestsCount] = useState(null);
  return (
    <CheckRemontPermission>
      <RemontWrapper>
        <Grid item xs={12}>
          <PageTitle title={`Заявки (${requestsCount || 0})`} />
          <Box padding={2}>
            <Requests showFilters={false} setRequestsCount={setRequestsCount} />
          </Box>
        </Grid>
      </RemontWrapper>
    </CheckRemontPermission>
  );
};

export default RemontRequests;
